<template>
  <div class="open-ticket">
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" class="open-ticket__loader">
        <!--        <base-loader class="open-ticket__loader-item" />-->
      </base-loader>
      <!--      <base-alert-->
      <!--        v-else-if="!isLoading && isServerError"-->
      <!--        :title="$t('errors.serverError.title')"-->
      <!--        icon="warn"-->
      <!--        class="cloud-info__alert"-->
      <!--      >-->
      <!--        {{ $t('errors.serverError.text') }}-->
      <!--      </base-alert>-->
      <base-empty
        v-else-if="isAstra && !isLoading && !list.length && !isServerError"
        :title="$t('astra.title')"
        :text="$t('astra.text')"
        class="cloud-info__empty"
        ><template #link>
          <base-button @click="addNewTicketCrm">
            {{ $t('new_ticket') }}
          </base-button>
        </template>
      </base-empty>
      <base-empty
        v-else-if="!isAstra && !isLoading && !list.length && !isServerError"
        :title="$t('title')"
        :text="$t('text')"
        class="cloud-info__empty"
        ><template #link>
          <base-button @click="addNewTicket">
            {{ $t('new_ticket') }}
          </base-button>
        </template>
      </base-empty>

      <card-block v-else :title="$t('title')" class="open-ticket__block">
        <transition name="fade" mode="out-in">
          <base-table :columns="tableHead" :list="paginList" class="open-ticket__table">
            <base-table-row
              v-for="(item, i) in paginList"
              :key="i"
              :columns="tableHead"
              :item="item"
              col-class="open-ticket__table-item__col"
            >
              <template #id>
                <router-link
                  :to="{ name: 'CurrentTicket', params: { id: item.id } }"
                  class="open-ticket__item-name standart-link"
                >
                  {{ item.id }}
                </router-link>
              </template>
              <template #theme>
                <!--                  :class="item['abuse_id'] ? 'error-text' : 'standart-text'"-->
                <span
                  class="open-ticket__item-realname standart-text"
                  :class="item.abuse_id ? 'alert-color' : 'standart-text'"
                >
                  {{ item.name }}
                </span>
              </template>
              <template #lastUpdate>
                <span class="open-ticket__item-email standart-text">
                  {{ item.last_message }}
                </span>
              </template>
              <template #status>
                <router-link
                  :to="{ name: 'CurrentTicket', params: { id: item.id } }"
                  class="open-ticket__item-name standart-link"
                >
                  <main-label :color="currentColor(item)" theme="plain">
                    {{ item.tstatus }}
                  </main-label>
                </router-link>
              </template>
            </base-table-row>
          </base-table>
        </transition>
        <NewPagination
          :step="pagin.step"
          :start="pagin.start || 0"
          :count="allTickets.length"
          type="base"
          @set-step="setStep"
          @new-pagination="changePagination"
        />
      </card-block>
    </transition>
  </div>
</template>

<script>
/**
 * @TODO:
 * - sort
 * - pagination
 * - ссылку на деталку перенести в тему
 * - отметить непрочитанные (item.unread === 'on'), стилизовать как в уведомлениях, например
 * - отформатировать дату и время в соответствии с локализацией (item.last_message)
 * - столбец #id вообще нужен?
 * - настроить стили таблицы / колонок так, чтобы на мобиле норм было
 * - добавить обработку ошибок сервера ( == пришедший ответ вообще не в кассу, например html с текстом "запрос обрабатывается")
 * - удалить неиспользуемые свойства (модалки из доверенных пользователей?)
 * - для определения цвета статуса использовать enum'оподобные структуры
 * (models/base/baseColors - список стандартных цветов приложения, с которыми сопоставлять статусы)
 */
import NewPagination from '@/components/Pagination/NewPagination';
import CardBlock from '@/layouts/Profile/Main/components/CardBlock';
import BaseTable from '@/components/Table/BaseTable';
import BaseTableRow from '@/components/Table/BaseTableRow';
import notifications from '@/mixins/notifications';
import mixin from '@/layouts/Profile/mixins';
import showErrorModal from '@/mixins/showErrorModal';
import MainLabel from '@/components/Labels/MainLabel.vue';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../mixins/index';
import CrmApi from '@/services/api/CrmApi';
import pagination from '@/mixins/pagination';
import provider from '@/mixins/provider';

export default {
  name: 'OpenTicket',
  components: {
    CardBlock,
    BaseTable,
    BaseTableRow,
    MainLabel,
    BaseEmpty,
    NewPagination,
  },
  mixins: [notifications, mixin, provider, showErrorModal, storeMixin, pagination],
  data() {
    return {
      isServerError: false,
      /*pagin: {
        start: 0,
        step: 10,
      },
      stepOptions: [10, 25, 50, 100, 'all'],*/
      tableHead: [
        {
          key: 'id',
          label: 'ID',
          style: {
            width: '80px',
            minWidth: '80px',
            // maxWidth: '80px',
          },
          // sort: {
          //   prop: 'id',
          //   order: 'asc',
          // },
        },
        {
          key: 'theme',
          label: this.$t('tableHead.theme'),
          style: {
            whiteSpace: 'nowrap',
            width: '400px',
            maxWidth: '500px',
          },
          // sort: {
          //   prop: 'theme',
          //   order: 'asc',
          // },
        },
        {
          key: 'lastUpdate',
          label: this.$t('tableHead.lastUpdate'),
          style: {
            whiteSpace: 'nowrap',
            width: '200px',
            maxWidth: '230px',
          },
          // sort: {
          //   prop: 'lastUpdate',
          //   order: 'asc',
          // },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            whiteSpace: 'nowrap',
            width: '144px',
          },
          // sort: {
          //   prop: 'status',
          //   order: 'asc',
          // },
        },
      ],
      settingsModal: null,
      rightsModal: null,
    };
  },
  computed: {
    allTickets() {
      return this.$store.getters['moduleSupport/GET_ALL_TICKETS'];
    },
    paginList() {
      //if (this.pagin.step === 'all') return this.allTickets;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.allTickets.slice(start, end);
    },
    isReady() {
      return this.allTickets && this.allTickets.length;
    },
  },

  // mounted() {
  //   if (!this.allTickets.length) {
  //     this.fetchAllTickets().catch(e => {
  //       if (e.status && e.status === 520) this.isServerError = true;
  //     });
  //   }
  // },
  mounted() {
    // if (this.isReady) this.isLoading = false;
    // if (!this.list.length) {
    if (!this.isLoading && !this.isReady) {
      this.fetch().catch(e => {
        if (e.status && e.status === 520) this.isServerError = true;
      });
    }
  },
  methods: {
    crmAuth() {
      const auth = {
        UserName: 'Supervisor',
        UserPassword: 'dsahHKJ721I_X!',
      };
      let url = 'ServiceModel/AuthService.svc/Login';
      return new Promise((resolve, reject) => {
        CrmApi.post(url, JSON.stringify(auth))
          .then(data => {
            console.log(data);
            resolve(data);
          })
          .catch(e => reject(e));
      });
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
    // fetchAllTickets() {
    //   this.isLoading = true;
    //   return this.$store
    //     .dispatch('moduleSupport/fetchAllTickets')
    //     .finally(() => (this.isLoading = false));
    // },
    // onSortChange(payload) {
    //   let { prop, order } = payload;
    //   if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
    //   this.$store.commit('SET_SORT', { prop, order });
    // },
    // updateSort(sort) {
    //   const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
    //   col.sort.order = sort.order;
    // },
    currentColor(item) {
      return item.tstatus_orig === '1_client' ? 'primary' : 'error';
    },
  },
};
</script>
<i18n>
{
  "ru": {

    "text": "Наши клиенты могут рассчитывать на круглосуточную техническую поддержку 24x7x365 по оказываемым нами услугам.",
    "new_ticket": "Новый запрос",
    "title": "Запрос в техподдержку",
    "astra": {
      "title": "Здесь вы можете создать новое обращение в службу поддержки.",
      "text": "Подтверждение регистрации обращения с номером заявки будет отправлено на ваш e-mail адрес, и вы сможете продолжить общение со службой поддержки по электронной почте (при ответах не изменяйте тему письма)."
    },
    "tableHead": {
      "theme": "Тема обращения",
      "lastUpdate": "Последнее обращение",
      "status": "Статус"
    },
    "status": {
      "work": "В работе",
      "done": "Ожидаем Вашего ответа"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.open-ticket {
  &__table {
    width: 100%;
    margin-bottom: 1.25rem;
    //overflow: hidden;

    &-item {
      font-size: 1rem;

      >>> &__col {
        vertical-align: middle;

        +breakpoint(xs-and-up) {
          text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
          z-index: 1;
        }

        &:hover {
          background: var(--main-card-bg);
          text-overflow: unset;
          z-index: 2;
        }
      }

      &__link {
        position: relative;
        padding: 0.5em;
        margin: -0.5em;
        color: inherit;
        text-decoration: none;
        white-space: nowrap;
        background: inherit;

        &:hover::after {
          +breakpoint(ml-and-up) {
            content: '';
            display: block;
            size(2.5em, 100%);
            absolute(0px, 100%);
            background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
          }
        }
      }
    }

    &__after {
      +breakpoint(sm-and-up) {
        flexy(flex-start, flex-start);
      }

      &-text {
        margin-bottom: 1.5rem;

        +breakpoint(sm-and-up) {
          margin-right: 1.5rem;
          margin-bottom: 0;
        }
      }
    }

    &__empty {
      max-width: 50rem;
    }

    &__add {
      width: 100%;
      +breakpoint(sm-and-up) {
        width: auto;
        flex: 0 0 auto;
      }
    }

    >>> &__item {
      vertical-align: middle;

      +breakpoint(md-and-down) {
        min-width: min-content;
        max-width: unset !important;
      }

      +breakpoint(ml-and-up) {
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        z-index: 1;
      }

      &:hover {
        background: var(--main-card-bg);
        text-overflow: unset;
        overflow: visible;
        z-index: 2;
      }
    }

    &__item {
      &-name,
      &-realname,
      &-email {
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        padding: 0.75rem;
        margin: -0.75rem;
        box-sizing: content-box;
        max-width: 100%;
        overflow: hidden;

        &::after {
          +breakpoint(ml-and-up) {
            content: '';
            display: block;
            size(2.5em, 100%);
            absolute(0px, 100%);
            position: absolute;
            top: 0;
            left: calc(100% - 0.75rem);
            background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
            opacity: 0;
          }
        }

        &:hover::after {
          +breakpoint(ml-and-up) {
            opacity: 1;
          }
        }

        +breakpoint(sm-and-up) {
          text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
</style>
